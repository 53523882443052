<template>
  <div>
    <div class="activity-detail container">
      <div class="activity-top flexRow">
        <div class="top-left">
          <div v-if="info.statusStr === '关闭'" class="top-left-tip active">{{ info.statusStr }}</div>
          <div v-else class="top-left-tip">{{ info.statusStr }}</div>
          <img :src="info.smallImage" />
        </div>
        <div class="top-right">
          <h3 class="">
            {{ info.name }}
          </h3>
          <div class="top-desc">
            <ul class="flexRow">
              <li><span class="flexRow-title">举办时间：</span>{{ info.startDateStr }}</li>
              <li class="">
                <span class="flexRow-title">活动主题：</span>
                {{ info.subject }}
              </li>
              <li><span class="flexRow-title">招募人数：</span>{{ info.personSize }}</li>
              <li><span class="flexRow-title">活动形式：</span>{{ info.form }}</li>
              <li><span class="flexRow-title">费用：</span>{{ info.cost }}</li>
              <li><span class="flexRow-title">地址：</span>{{ info.address }}</li>
            </ul>
          </div>
          <div class="top-btn" v-if="info.statusStr === '关闭'">
            <!----><a href="javascript:;" class="close">已结束</a>
          </div>

          <div class="top-btn" v-else>
            <!----><a href="javascript:void;" class="" @click="signUp"
              >我要报名 ></a
            >
          </div>
        </div>
      </div>
      <div class="activity-content flexRow">
        <div class="content-left">
          <div class="activity-title flexRow">
            <div class="txt">活动详情</div>
            <div class="txt-border"></div>
          </div>
          <div class="content-img">
            <div class="ql-editor">
              <div v-html="info.detail"></div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="hot-right-news">
            <h3 class="hot-title">热门活动</h3>
            <div class="list">
              <div class="hot-item" v-for="item in hotList" :key="item.id">
                <a :href="'/site/detail?id=' + item.id" class=""
                  ><h4 class="overflowTwo">
                    {{ item.name }}
                  </h4>
                  <p class="time">{{ item.startDateStr }}</p></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="请扫码报名"
        v-model="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div style="text-align:center">
          <img style="width:100%;height:100%" :src="info.barcode" alt="" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { activities } from '@/api/home'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const active = ref(0)
    const info = ref({})
    const news = ref(null)
    const id = String(router.currentRoute.value.query.id)
    const hotList = ref([])
    const dialogVisible = ref(false)
    onMounted(async () => {
      getIncubateFunc()
    })
    const getIncubateFunc = async () => {
      const data = await activities({ id })
      console.log(data)
      info.value = data.data.activity
      hotList.value = data.data.hotActivities
    }
    const signUp = () => {
      dialogVisible.value = true
    }
    return {
      signUp,
      dialogVisible,
      hotList,
      info,
      active,
      news
    }
  }
})
</script>

<style lang="scss" scoped>

.hot-item:hover{
  background-color: #F7F7F7;
}
.activity-detail {
  text-align: left;
  padding: 140px 0 150px;
  .activity-top {
    justify-content: space-between;
    margin-bottom: 100px;
    display: flex;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.flexRow-title{
  font-size: 16px;
  font-weight: 600;
  color: #F2C000;
  line-height: 22px;
}
.activity-detail .activity-top .top-left {
  position: relative;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
::v-deep.ql-editor .ql-align-center {
  text-align: center;
}
.activity-detail .activity-top .top-left,
.activity-detail .activity-top .top-left img {
  width: 100%;
  height: 310px;
}
.activity-detail .activity-top .top-left .top-left-tip.active {
  background: #cbcbcb;
}

.activity-detail .activity-top .top-left .top-left-tip {
  width: 48px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #ffd00b;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 8px;
}
.activity-detail .activity-top .top-left,
.activity-detail .activity-top .top-left img {
  width: 310px;
  height: 310px;
  margin: 0 auto;
}
.activity-detail .activity-top .top-right {
  // width: 758px;
  text-align: left;
  flex: 1;
  padding-left: 30px;
}
.activity-detail .activity-top .top-right h3 {
  font-size: 30px;
  line-height: 42px;
  // height: 84px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 33px;
}

.overflowTwo {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.overflowOne,
.overflowTwo {
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-detail .activity-top .top-right .top-desc ul {
  flex-wrap: wrap;
}
.activity-detail .activity-top .top-right .top-desc ul li:nth-child(2n) {
  width: 470px;
}

.activity-detail .activity-top .top-right .top-desc ul li {
  width: 285px;
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
}
.activity-detail .activity-top .top-right .top-btn {
  transition: all ease .6s;
  margin-top: 36px;
}
.activity-detail .activity-top .top-right .top-btn a.close {
  padding: 0;
  width: 200px;
  background: #cbcbcb;
  text-align: center;
}

.activity-detail .activity-top .top-right .top-btn a {
  color: #000;
  text-decoration: none;
  display: block;
  width: 177px;
  height: 60px;
  line-height: 60px;
  // padding-left: 23px;
  text-align: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAAsElEQVQYV1XOPQ6CQBCG4W9oiFRUljZ7AfEelDSbXRrJGk08g9yFodb423gBG+MNjPEQdIxZIkS/avI+zRAAMsYkzHzHz8haOxGRm4is6rre9kb+0FpPgyC4AFgy8863DvzyPE9E5CwiC2beD+DRGDMDcCIi9wfOuahpmquIPAZI0zSK4/gI4FlVVdFBlmWjMAx9fDFzAaClbzwQ0VspNS/Lsu2+staOAayVUps+evgAa5FAGtJEr5QAAAAASUVORK5CYII=)
    no-repeat 179px;
  background-color: #ffd00b;
  font-size: 14px;
  font-weight: 400;
}
.activity-detail .activity-content {
  justify-content: space-between;
}
.activity-detail .activity-content .content-left {
  flex: 1;
}
.activity-detail .activity-content .content-left .activity-title {
  margin-bottom: 35px;
  line-height: 22px;
}
.activity-detail .activity-content .content-left .activity-title .txt {
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
}
.activity-detail .activity-content .content-left .activity-title .txt-border {
  flex: 1;
  border-top: 1px solid hsla(0, 0%, 77.6%, 0.7);
  margin-top: 10px;
}
.activity-detail .activity-content .content-right {
  width: 320px;
  margin: 0 auto;

  margin-top: 60px;
}
.hot-right-news {
  text-align: left;
}
.hot-right-news .hot-title {
  font-size: 16px;
  font-weight: 700;
  color: #f2c000;
  margin-bottom: 10px;
}
.hot-right-news .hot-item {
  transition: all ease .6s;
  text-align: left;
  padding: 0 20px;
}
.hot-right-news .hot-item:first-child a {
  border-top: none;
}
.hot-right-news .hot-item a {
  display: block;
  padding: 35px 0;
  border-top: 1px solid hsla(0, 0%, 77.6%, 0.15);
  color: #000;
  text-decoration: none;
}
.hot-right-news .hot-item a h4 {
  font-size: 16px;
  font-weight: 700;
  color: #121212;
  height: 44px;
  line-height: 22px;
}
.hot-right-news .hot-item a p {
  margin-top: 10px;
  font-size: 12px;
}
@media (max-width: 756px) {
  .activity-title.flexRow{
  padding: 0 15px;
}
  .activity-top {
    flex-direction: column !important;
  }
  .activity-content {
    flex-direction: column !important;
  }
  .activity-detail {
    padding: 60px 0 150px;
  }
}
</style>
