
import variables from '@/assets/style/index.scss'
import { getDictMultiple, getDict, finance } from '@/api/home'
import { ref, defineComponent, onMounted, inject, Ref } from 'vue'
type site = {
  siteName: string
  id: string
}
interface incubatorsType {
  boosters: site[]
  incubators: site[]
  province: string
  provinceStr: string
}

export default defineComponent({
  setup() {
    const isH5 = inject('isH5')
    const amountStatus = inject('amountStatus')
    const date = ref(null)
    const createFinanceDTO = ref({})
    const industry1 = ref([])
    const industry2 = ref([])
    const request = ref([])
    const stage = ref([])
    const provinceList = ref([])
    const siteList = ref()
    const siteDetail = inject('siteDetail')
    const isSuccess = ref(false)
    onMounted(() => {
      getDictMultipleFunc()
      setTimeout(() => {
        getProvince()
      }, 1000)
    })
    const getProvince = () => {
      (siteDetail as Ref).value.map((e: incubatorsType) => {
        if (e.provinceStr) {
          (provinceList as Ref).value.push({
            label: e.provinceStr,
            value: e.provinceStr
          })
        }
      })
    }
    const selectProvince = (e: string) => {
      siteList.value = []
      ;(siteDetail as Ref).value.map((item: incubatorsType) => {
        if (e === item.provinceStr) {
          siteList.value.push(
            ...item.incubators.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            }),
            ...item.boosters.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            })
          )
        }
      })
    }
    const handleClose = () => {
      (amountStatus as Ref).value = false
      isSuccess.value = false
    }
    const getDictMultipleFunc = async () => {
      const dicts = ['INDUSTRY_FIELD', 'SETTLED_REQUEST', 'SETTLED_STAGE']
      const data = await getDictMultiple(dicts.join(','))
      industry1.value = data.data.INDUSTRY_FIELD
      request.value = data.data.SETTLED_REQUEST
      stage.value = data.data.SETTLED_STAGE
    }
    const changeIndustry = async (e: string) => {
      console.log(e)
      const data = await getDict(e)
      industry2.value = data.data
    }
    const changeDate = (e: Date) => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }

    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        console.log(reg.test(value))
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }

    return {
      rules: {
        industry1: [{ required: true, message: '选择领域', trigger: 'change' }],
        industry2: [{ required: true, message: '选择行业', trigger: 'change' }],
        stage: [{ required: true, message: '所处阶段', trigger: 'change' }],
        name: [{ required: true, message: '项目名称', trigger: 'blur' }],
        description: [{ required: true, message: '项目介绍', trigger: 'blur' }],
        amount: [{ required: true, message: '融资金额', trigger: 'blur' }],
        proportion: [{ required: true, message: '出让比例', trigger: 'blur' }],
        applicant: [
          { required: true, message: '请填写申请人', trigger: 'blur' }
        ],
        contact: [
          {
            required: true,
            message: '请填写联系方式',
            validator: checkPhone,
            trigger: 'blur'
          }
        ]
      },
      selectProvince,
      siteList,
      provinceList,
      isSuccess,
      changeIndustry,
      industry2,
      industry1,
      request,
      stage,
      createFinanceDTO,
      date,
      changeDate,
      isH5,
      handleClose,
      variables,
      amountStatus,
      radio1: ref(1),
      value: ref(1)
    }
  },
  methods: {
    settledFunc(ruleForm: string) {
      (this.$refs as any)[ruleForm].validate(async (valid: any) => {
        if (valid) {
          const data = await finance(this.createFinanceDTO)
          if ((data as any).returnCode === '2000') {
            this.isSuccess = true
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
})
