
import variables from '@/assets/style/index.scss'
import { getDictMultiple, getDict, settled } from '@/api/home'
import { ref, defineComponent, onMounted, inject, Ref } from 'vue'
type site = {
  siteName: string
  id: string
}
interface incubatorsType {
  boosters: site[]
  incubators: site[]
  province: string
  provinceStr: string
}

export default defineComponent({
  setup() {
    const isH5 = inject('isH5')
    const applyStatus = inject('applyStatus')
    const date = ref(null)
    const createSettledDTO = ref({})
    const industry1 = ref([])
    const industry2 = ref([])
    const request = ref([])
    const stage = ref([])
    const provinceList = ref([])
    const siteList = ref()
    const siteDetail = inject('siteDetail')
    const isSuccess = ref(false)
    onMounted(() => {
      getDictMultipleFunc()
      setTimeout(() => {
        getProvince()
      }, 1000)
    })
    const getProvince = () => {
      (siteDetail as Ref).value.map((e: incubatorsType) => {
        if (e.provinceStr) {
          (provinceList as Ref).value.push({
            label: e.provinceStr,
            value: e.provinceStr
          })
        }
      })
    }
    const selectProvince = (e: string) => {
      siteList.value = []
      ;(siteDetail as Ref).value.map((item: incubatorsType) => {
        if (e === item.provinceStr) {
          siteList.value.push(
            ...item.incubators.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            }),
            ...item.boosters.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            })
          )
        }
      })
    }
    const handleClose = () => {
      (applyStatus as Ref).value = false
      isSuccess.value = false
    }
    const getDictMultipleFunc = async () => {
      const dicts = ['INDUSTRY_FIELD', 'SETTLED_REQUEST', 'SETTLED_STAGE']
      const data = await getDictMultiple(dicts.join(','))
      industry1.value = data.data.INDUSTRY_FIELD
      request.value = data.data.SETTLED_REQUEST
      stage.value = data.data.SETTLED_STAGE
    }
    const changeIndustry = async (e: string) => {
      console.log(e)
      const data = await getDict(e)
      industry2.value = data.data
    }
    const changeDate = (e: Date) => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }

    const checkPhone = (rule: any, value: string, cb: any) => {
      const reg = /^1\d{10}$/
      if (!reg.test(value)) {
        cb(new Error('联系方式格式不正确'))
      } else {
        cb()
      }
    }

    return {
      rules: {
        province: [
          { required: true, message: '选择入驻站点省份', trigger: 'change' }
        ],
        siteId: [
          { required: true, message: '选择入驻站点名称', trigger: 'change' }
        ],
        industry1: [{ required: true, message: '选择领域', trigger: 'change' }],
        industry2: [{ required: true, message: '选择行业', trigger: 'change' }],
        stage: [{ required: true, message: '所处阶段', trigger: 'change' }],
        name: [{ required: true, message: '项目名称', trigger: 'blur' }],
        description: [{ required: true, message: '项目介绍', trigger: 'blur' }],
        proportion: [{ required: true, message: '融资比例', trigger: 'blur' }],
        request: [{ required: true, message: '入驻需求', trigger: 'change' }],
        inDate: [
          {
            type: 'date',
            required: true,
            message: '请选择入驻时间',
            trigger: 'change'
          }
        ],
        personSize: [
          { required: true, message: '请填写入驻人数', trigger: 'blur' }
        ],
        applicant: [
          { required: true, message: '请填写申请人', trigger: 'blur' }
        ],
        contact: [
          {
            required: true,
            message: '请填写联系方式',

            trigger: 'blur'
          },
          { validator: checkPhone }
        ]
      },
      selectProvince,
      siteList,
      provinceList,
      isSuccess,
      changeIndustry,
      industry2,
      industry1,
      request,
      stage,
      createSettledDTO,
      date,
      changeDate,
      isH5,
      handleClose,
      variables,
      applyStatus,
      radio1: ref(1),
      value: ref(1),
      options: ref([
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ])
    }
  },
  methods: {
    settledFunc(ruleForm: string) {
      (this.$refs as any)[ruleForm].validate(async (valid: any) => {
        if (valid) {
          const data = await settled(this.createSettledDTO)
          if ((data as any).returnCode === '2000') {
            this.isSuccess = true
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
})
