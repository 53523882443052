
import variables from '@/assets/style/index.scss'
import { getDictMultiple, getDict, visit } from '@/api/home'
import { ref, defineComponent, onMounted, inject, Ref } from 'vue'
type site = {
  siteName: string
  id: string
}
interface incubatorsType {
  boosters: site[]
  incubators: site[]
  province: string
  provinceStr: string
}

export default defineComponent({
  setup() {
    const isH5 = inject('isH5')
    const orderStatus = inject('orderStatus')
    const date = ref(null)
    const createVisitDTO = ref({})
    const industry1 = ref([])
    const industry2 = ref([])
    const request = ref([])
    const stage = ref([])
    const provinceList = ref([])
    const siteList = ref()
    const siteDetail = inject('siteDetail')
    const isSuccess = ref(false)
    onMounted(() => {
      getDictMultipleFunc()
      setTimeout(() => {
        getProvince()
      }, 1000)
    })
    const getProvince = () => {
      (siteDetail as Ref).value.map((e: incubatorsType) => {
        if (e.provinceStr) {
          (provinceList as Ref).value.push({
            label: e.provinceStr,
            value: e.provinceStr
          })
        }
      })
    }
    const selectProvince = (e: string) => {
      siteList.value = []
      ;(siteDetail as Ref).value.map((item: incubatorsType) => {
        if (e === item.provinceStr) {
          siteList.value.push(
            ...item.incubators.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            }),
            ...item.boosters.map(e => {
              return {
                label: e.siteName,
                value: e.id
              }
            })
          )
        }
      })
    }
    const handleClose = () => {
      (orderStatus as Ref).value = false
      isSuccess.value = false
    }
    const getDictMultipleFunc = async () => {
      const dicts = ['INDUSTRY_FIELD', 'SETTLED_REQUEST', 'SETTLED_STAGE']
      const data = await getDictMultiple(dicts.join(','))
      industry1.value = data.data.INDUSTRY_FIELD
      request.value = data.data.SETTLED_REQUEST
      stage.value = data.data.SETTLED_STAGE
    }
    const changeIndustry = async (e: string) => {
      console.log(e)
      const data = await getDict(e)
      industry2.value = data.data
    }
    const changeDate = (e: Date) => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }
    const validatePass = (rule: any, value: string, cb: any) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,}$/
      if (!reg.test(value)) {
        cb(new Error('邮箱格式不正确'))
      } else {
        cb()
      }
    }

    const validatePass2 = (rule: any, value: string, cb: any) => {
      const reg = /^1\d{10}$/
      if (!reg.test(value)) {
        cb(new Error('联系方式格式不正确'))
      } else {
        cb()
      }
    }
    return {
      rules: {
        province: [{ required: true, message: '选择省份', trigger: 'change' }],
        siteId: [
          { required: true, message: '选择站点名称', trigger: 'change' }
        ],
        name: [{ required: true, message: '单位名称', trigger: 'blur' }],
        orderDate: [
          {
            type: 'date',
            required: true,
            message: '请选择时间',
            trigger: 'change'
          }
        ],
        applicant: [
          { required: true, message: '请填写申请人', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            validator: validatePass2
          }
        ],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' },
          {
            validator: validatePass
          }
        ],
        purpose: [
          { required: true, message: '请填写参观目的', trigger: 'blur' }
        ]
      },
      selectProvince,
      siteList,
      provinceList,
      isSuccess,
      changeIndustry,
      industry2,
      industry1,
      request,
      stage,
      createVisitDTO,
      date,
      changeDate,
      isH5,
      handleClose,
      variables,
      orderStatus,
      radio1: ref(1),
      value: ref(1)
    }
  },
  methods: {
    settledFunc(ruleForm: string) {
      (this.$refs as any)[ruleForm].validate(async (valid: any) => {
        if (valid) {
          const data = await visit(this.createVisitDTO)
          if ((data as any).returnCode === '2000') {
            this.isSuccess = true
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
})
